import React from "react";
import Nav from "./Components/Nav";
import Hero from "./Components/Hero";
import AboutMe from "./Components/AboutMe";
import Projects from "./Components/Projects";
import Contact from "./Components/Contact";

const App = () => {
  return (
    <div>
      <div class="hero-bg">
        <Nav />
        <Hero />
      </div>
      <AboutMe />
      <Projects />
      <Contact />
    </div>
  );
};

export default App;
