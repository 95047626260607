import React from "react";

const Nav = () => {
  return (
    <nav>
      <div className="nav-container">
        <ul className="d-flex flex-row justify-content-center align-items-center fw-bold">
          <li className="grow">
            <a href="#about" className="fancy-hover text-white">
              about
            </a>
          </li>
          <li className="grow">
            <a href="#work" className="fancy-hover text-white">
              work
            </a>
          </li>
          <li className="grow">
            <a href="#contact" className="fancy-hover text-white">
              contact
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Nav;
