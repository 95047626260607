import React from 'react';
import '../custom_scss/custom.scss';
import FlyingCoder from './FlyingCoder';

const Hero = () => {
  return (
    <section className="main-container-hero">
      <div className="d-flex flex-column flex-start left-hero text-nowrap">
        <h1 className="display-4 text-white">Hi, I'm John</h1>
        <div>
          <h1 className="display-4 text-white headline-underline">
            Software Engineer
          </h1>
        </div>
        <div className="social-display mt-3">
          <a href="https://github.com/jkol7" target="_blank">
            <i className="bi bi-github me-4" style={{fontSize: '40px'}}></i>
          </a>
          <a href="https://www.linkedin.com/in/jkol7/" target="_blank">
            <i className="bi bi-linkedin me-4" style={{fontSize: '40px'}}></i>
          </a>
        </div>
      </div>
      <div className="right-hero">
        <FlyingCoder></FlyingCoder>
      </div>
    </section>
  );
};

export default Hero;
