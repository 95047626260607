import React from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

const AboutMe = () => {
  const iconStyle = {
    fontSize: '28px',
    color: 'black',
  };

  return (
    <section
      className="aboutme-main bg-white d-lg-flex flex-lg-column"
      id="about"
    >
      <div className="text-dark d-flex justify-content-center pt-4">
        <h2 className="category-underline">About Me</h2>
      </div>
      <div className="aboutme-container d-flex justify-content-evenly align-items-start pt-5">
        <img src="johnkimg.jpg" alt="john's face"></img>
        <div className="text-black px-5">
          <p>
            I'm a full-stack developer with agile experience who loves to learn
            and solve challenges. I have a&nbsp;
            <Tippy
              content={<img src="review-min.jpg" alt="Five start review" />}
            >
              <span className="d-inline text-nowrap dotted-underline">
                proven
              </span>
            </Tippy>
            <span>
              &nbsp;track record of creating and implementing successful front
              and back-end&nbsp;
            </span>
            <Tippy content="React.js, Node.js, Bootstrap, and more">
              <span className="d-inline text-nowrap dotted-underline">
                web applications
              </span>
            </Tippy>
            <span>&nbsp;for clients.</span>
          </p>
          <span className="d-inline text-nowrap second-line">
            I've lived in{' '}
          </span>
          <Tippy content={<img src="michael.gif" alt="Michael Scott" />}>
            <span className="d-inline text-nowrap dotted-underline">
              Scranton, PA
            </span>
          </Tippy>
          <span className="d-inline">
            &nbsp;for most of my life. I love photography, volleyball, gaming,
            and hiking. If you've read this far, here's a photo of&nbsp;
          </span>
          <Tippy content={<img src="shayde.jpg" alt="My dog" />}>
            <span className="d-inline text-nowrap dotted-underline">
              my dog
            </span>
          </Tippy>
          <span>, because who doesn't love dogs?</span>
          <h5 className="pt-4 font-weight-bold">
            <i class="bi bi-clipboard2-data-fill pe-1" style={iconStyle}></i>
            Technologies
          </h5>
          <div className="pt-3 d-flex gap-2 flex-wrap tech-section">
            <span class="badge rounded-pill text-bg-secondary">React.JS</span>
            <span class="badge rounded-pill text-bg-secondary">JavaScript</span>
            <span class="badge rounded-pill text-bg-secondary">Node.JS</span>
            <span class="badge rounded-pill text-bg-secondary">Github</span>
            <span class="badge rounded-pill text-bg-secondary">Bootstrap</span>
            <span class="badge rounded-pill text-bg-secondary">SASS</span>
            <span class="badge rounded-pill text-bg-secondary">MongoDB</span>
            <span class="badge rounded-pill text-bg-secondary">Express.JS</span>
            <span class="badge rounded-pill text-bg-secondary">GraphQL</span>
            <span class="badge rounded-pill text-bg-secondary">MaterialUI</span>
            <span class="badge rounded-pill text-bg-secondary">HTML/CSS</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutMe;
